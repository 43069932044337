<template>
  <div class="col-xs-12 col-md-6 p0 product-block-wrapper relative">
    <div class="py10 mx10 product-block">
      <div class="row m0">
        <div :id="product.identifier" class="col-xs-3 p0 preview-image-wrapper" />
        <div class="col-xs-9 margin-auto pl15 middle-xs">
          <div class="flex-1">
            <div class="flex between-xs product-name-wrapper mb5 row">
              <h5 class="product-name col-xs pl8 mt0 mb0 secondary-font weight-700 uppercase align-left">
                {{ product.name }}
              </h5>
            </div>
            <div class="row">
              <template v-if="product.size || product.shoe_size">
                <p class="col-xs-3 my5 align-left paragraph first">
                  {{ `${$t("Rozmiar")}: ` }}
                </p>
                <p class="col-xs-9 my5 pl10 align-left uppercase bold-values">
                  {{ product.size || product.shoe_size }}
                </p>
              </template>
              <p class="col-xs-3 mt5 mb0 align-left paragraph">
                {{ `${$t("Price")}: ` }}
              </p>
              <p class="col-xs-9 mt5 mb0 pl10 align-left bold-values">
                {{ product.marketplace_price | price(storeView) }}
              </p>
            </div>
            <button
              type="button"
              class="col-xs-1 bg-cl-transparent brdr-none p0 absolute delete-button"
              @click="removeProduct(product)"
            >
              <div class="flex align-center">
                <img src="/assets/delete.svg">
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export default {
  name: 'ProductBlock',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    storeView () {
      return currentStoreView()
    }
  },
  mounted () {
    this.loadImage()
  },
  methods: {
    loadImage () {
      let oFReader = new FileReader()
      oFReader.readAsDataURL(this.product.uploadedImages.image)
      // todo add image validation
      oFReader.onload = (oFREvent) => {
        let img = document.createElement('img')
        img.src = oFREvent.target.result
        let imageBox = document.getElementById(this.product.identifier)
        if (!imageBox) return
        imageBox.innerHTML = ''
        imageBox.appendChild(img)
      }
    },
    removeProduct (product) {
      this.$store.dispatch('selling/removeProduct', product)
    }
  }
}
</script>

<style lang="scss" scoped>
.product-block-wrapper {
  &:nth-child(n+3) {
    margin-top: -1px;
  }
}
.product-block {
  height: 100%;
  box-sizing: border-box;
  border-top: 1px solid #bfbfbf;
  border-bottom: 1px solid #bfbfbf;
  @media (max-width: 768px) {
    max-width: unset;
    min-width: unset;
    margin-right: unset;
    border-bottom: none;
    &:not(:first-child) {
      border-top: none;
    }
  }
}
.product-name-wrapper {
  @media (max-width: 992px) {
    position: relative;
  }
}
.product-name {
  letter-spacing: 1.04px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
}
.paragraph {
  font-size: 12px;
}
.bold-values {
  font-weight: 700;
  font-size: 14px
}
.custom-icon {
  font-size: 31px;
}
.flex-1 {
  flex: 1;
}
/deep/ .preview-image-wrapper {
  position: relative;
  height: 0;
  padding-top: 26%;
  & > img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left:0;
    right:0;
    margin: auto;
  }
}
.margin-auto {
  margin: auto
}
.delete-button {
  top: 10px;
  right: 15px;
}
</style>
