<template>
  <div class="container">
    <div class="center-xs">
      <spinner v-if="!product" />
    </div>
    <no-ssr>
      <div v-if="product" class="row mb80 justify-center">
        <div class="col-sm-12 col-md-9 col-lg-8 col-xl-5">
          <h2 class="center-xs step-title">
            {{ $t('Add products') }}
          </h2>
          <div class="row">
            <multi-select
              class="col-xs-12 mb10"
              name="product-categories"
              v-model="product.categories"
              :type="'single'"
              :options="preparedCategoriesOptionsByLang"
              :placeholder="$t('Category') + ' *'"
              :validations="[
                {
                  condition: $v.product.categories.$error,
                  text: $t('Field is required.')
                }
              ]"
              @input="$v.product.categories.$touch(), changeCategory()"
              @blur="$v.product.categories.$touch()"
            />
          </div>
          <div class="row">
            <!-- dynamic attrs -->
            <template v-for="attrCode in getSortedProductAttributesNames">
              <multi-select
                v-if="preparedProductsByLang[attrCode].inputType === 'multiSelect'"
                :key="attrCode"
                class="col-xs-12 mb10"
                :name="`product-${attrCode}`"
                v-model="product[attrCode]"
                :type="'multi'"
                :options="preparedDropdownAttrsOptionsByLang[attrCode]"
                :placeholder="`${preparedProductsByLang[attrCode].label} *`"
                :validations="[
                  {
                    condition: $v.product[attrCode].$error && !$v.product[attrCode].required,
                    text: $t('Field is required.')
                  }
                ]"
                @input="$v.product[attrCode].$touch()"
                @blur="$v.product[attrCode].$touch()"
              />
              <base-select
                v-if="preparedProductsByLang[attrCode].inputType === 'baseSelect'"
                :key="attrCode"
                class="col-xs-12 col-md-6 mb10 bold-input"
                :name="`product-${attrCode}`"
                v-model="product[attrCode]"
                :placeholder="`${preparedProductsByLang[attrCode].label} *`"
                :options="preparedDropdownAttrsOptionsByLang[attrCode]"
                :selected="product[attrCode]"
                :validations="[
                  {
                    condition: $v.product[attrCode].$error && !$v.product[attrCode].required,
                    text: $t('Field is required.')
                  }
                ]"
                @blur="$v.product[attrCode].$touch()"
              />
              <base-input
                v-if="preparedProductsByLang[attrCode].inputType === 'baseInput'"
                :key="attrCode"
                type="text"
                class="col-xs-12 mb10 bold-input"
                :name="`product-${attrCode}`"
                v-model="product[attrCode]"
                :placeholder="`${preparedProductsByLang[attrCode].label} ${attrCode === 'phone_number' ? '' : ' *'}`"
                :validations="[
                  {
                    condition: $v.product[attrCode].$error && !$v.product[attrCode].required,
                    text: $t('Field is required.')
                  }
                ]"
                @blur="$v.product[attrCode].$touch()"
              />
              <base-textarea
                v-if="preparedProductsByLang[attrCode].inputType === 'baseTextarea'"
                :key="attrCode"
                type="text"
                class="col-xs-12 mb5 mt0 product-description bold-input"
                :name="`product-${attrCode}`"
                v-model="product[attrCode]"
                :placeholder="`${preparedProductsByLang[attrCode].label} *`"
                :maxlength="250"
                :validations="[
                  {
                    condition: $v.product[attrCode].$error && !$v.product[attrCode].required,
                    text: $t('Field is required.')
                  }
                ]"
                @blur="$v.product[attrCode].$touch()"
              />
              <div
                v-if="preparedProductsByLang[attrCode].inputType === 'number'"
                class="row w-100 mx0"
                :key="attrCode"
              >
                <base-input
                  class="col-xs-12 col-md-6 mb10 bold-input"
                  type="number"
                  name="product-marketplace_price"
                  v-model="product[attrCode]"
                  :placeholder="`${preparedProductsByLang[attrCode].label} (${storeView.i18n.currencyCode}) *` "
                  :validations="[
                    {
                      condition: $v.product[attrCode].$error && !$v.product[attrCode].positive,
                      text: $t(`The price cannot be a negative number.`)
                    },
                    {
                      condition: $v.product[attrCode].$error && !$v.product[attrCode].required,
                      text: $t('Field is required.')
                    }
                  ]"
                  @blur="$v.product[attrCode].$touch()"
                />
                <div class="col-xs-12 col-md-6 mb10">
                  <p :class="{ 'weight-700' : product[attrCode] }" class="profit-paragraph">
                    <template v-if="!product[attrCode]">
                      {{ $t('Your income') }} {{ profitPercent }}
                    </template>
                    <template v-else>
                      {{ `${$t('Your income')}: ` }} {{ profitCount | price(storeView) }}
                    </template>
                    <tooltip-on-element :text="$t('E-Garderobe charges a 22% commission on product sales. In addition to the sale, an additional 3% fee is charged to cover transaction processing costs.')">
                      <img class="svg-info" src="/assets/info.svg" alt="info logo">
                    </tooltip-on-element>
                  </p>
                </div>
              </div>
            </template>
            <!-- dynamic attrs end -->
          </div>
          <div class="row imageInput">
            <div class="col-xs-12">
              <div class="imageInput-outer">
                <p class="center-xs imageInput-title">
                  {{ $t('Add photos') }}
                </p>
                <div class="row">
                  <div class="imageInput-inner col-xs-6 col-md-3">
                    <label class="imageInput-label" for="imageInput-image">
                      <input id="imageInput-image" type="file" accept=".jpg, .jpeg, .png" @change="onFileImport">
                      <template v-if="!product.uploadedImages['image']">
                        <p class="image-name">{{ $t('Front image') }}</p>
                        <img class="svg-camera" src="/assets/camera_icon.svg">
                        <img class="svg-plus" src="/assets/plus.svg" alt="plus logo">
                      </template>
                      <template v-else>
                        <i slot="close" class="pointer icon-close" />
                      </template>
                    </label>
                    <div v-show="product.uploadedImages['image']" id="previewImageBoximage" class="preview-image-box" />
                  </div>
                  <div class="imageInput-inner col-xs-6 col-md-3">
                    <label class="imageInput-label" for="imageInput-back_image">
                      <input id="imageInput-back_image" type="file" accept=".jpg, .jpeg, .png" @change="onFileImport">
                      <template v-if="!product.uploadedImages['back_image']">
                        <p class="image-name">{{ $t('Back image') }}</p>
                        <img class="svg-camera" src="/assets/camera_icon.svg">
                        <img class="svg-plus" src="/assets/plus.svg" alt="plus logo">
                      </template>
                      <template v-else>
                        <i slot="close" class="pointer icon-close" />
                      </template>
                    </label>
                    <div v-show="product.uploadedImages['back_image']" id="previewImageBoxback_image" class="preview-image-box" />
                  </div>
                  <div class="imageInput-inner col-xs-6 col-md-3">
                    <label class="imageInput-label" for="imageInput-side_image">
                      <input id="imageInput-side_image" type="file" accept=".jpg, .jpeg, .png" @change="onFileImport">
                      <template v-if="!product.uploadedImages['side_image']">
                        <p class="image-name">{{ $t('Side image') }}</p>
                        <img class="svg-camera" src="/assets/camera_icon.svg">
                        <img class="svg-plus" src="/assets/plus.svg" alt="plus logo">
                      </template>
                      <template v-else>
                        <i slot="close" class="pointer icon-close" />
                      </template>
                    </label>
                    <div v-show="product.uploadedImages['side_image']" id="previewImageBoxside_image" class="preview-image-box" />
                  </div>
                  <div class="imageInput-inner col-xs-6 col-md-3">
                    <label class="center-xs imageInput-label" for="imageInput-label_image">
                      <input id="imageInput-label_image" type="file" accept=".jpg, .jpeg, .png" @change="onFileImport">
                      <template v-if="!product.uploadedImages['label_image']">
                        <p class="image-name">{{ $t('Inside label image') }}</p>
                        <img class="svg-camera" src="/assets/camera_icon.svg">
                        <img class="svg-plus" src="/assets/plus.svg" alt="plus logo">
                      </template>
                      <template v-else>
                        <i slot="close" class="pointer icon-close" />
                      </template>
                    </label>
                    <div v-show="product.uploadedImages['label_image']" id="previewImageBoxlabel_image" class="preview-image-box" />
                  </div>
                </div>
                <p v-if="$v.product.uploadedImages.$error" class="cl-error center-xs imageInput-thirdText">
                  {{ $t('Field is required.') }}
                </p>
              </div>
              <p class="center-xs imageInput-thirdText">
                {{ $t('The photos you add are only for the first stage of E-Garderobe verification and will not be used on the page of the product you sell.') }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-6">
              <button-outline class="w-100 mt10" @click.native="clearProductForm()" :disabled="!$v.$anyDirty">
                {{ $t('Clear form') }}
              </button-outline>
            </div>
            <div class="col-xs-6">
              <button-outline class="w-100 mt10" @click.native="addProduct(product)" :disabled="mustBeValidated && $v.$invalid">
                {{ $t('Add another product') }}
              </button-outline>
            </div>
            <div class="col-xs-12 mt10">
              <tooltip-on-element class="w-100" :disabled="!(mustBeValidated && $v.$invalid)" :text="$t('You must fill in all the fields of the form to proceed')">
                <button-full class="w-100" @click.native="goNextStep" :disabled="mustBeValidated && $v.$invalid">
                  {{ $t('Save and go to shipping') }}
                </button-full>
              </tooltip-on-element>
            </div>
          </div>
        </div>
      </div>
    </no-ssr>
    <div v-if="product" class="row center-xs">
      <div class="flex wrap col-xs-10 col-sm-8 col-md-10 col-lg-8 col-xl-6" :class="{'center-xs': getProductsCount === 1}">
        <product-block v-for="addedProduct in getProducts" :product="addedProduct" :key="addedProduct.key" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseSelect from 'theme/components/core/blocks/Form/BaseSelect'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import BaseTextarea from 'theme/components/core/blocks/Form/BaseTextarea'
import ButtonFull from 'theme/components/theme/ButtonFull'
import ButtonOutline from 'theme/components/theme/ButtonOutline'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import ProductBlock from 'theme/components/core/blocks/Selling/ProductBlock'
import { isCanBeReached, prepareIdentifier } from 'theme/helpers/selling-products'
import cloneDeep from 'lodash-es/cloneDeep'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import config from 'config'
import TooltipOnElement from 'theme/components/core/TooltipOnElement'
import MultiSelect from 'theme/components/core/blocks/Form/MultiSelect'
import NoSSR from 'vue-no-ssr'
import Spinner from 'theme/components/core/Spinner'

const customObjRequired = value => {
  return Object.keys(value).every(key => value[key])
}
const customArrayRequired = value => {
  return Array.isArray(value) && value.length > 0
}
const positive = value => {
  return Number(value) > 0
}

export default {
  name: 'AddProductsStep',
  components: {
    BaseSelect,
    BaseInput,
    BaseTextarea,
    ButtonFull,
    ButtonOutline,
    ProductBlock,
    TooltipOnElement,
    MultiSelect,
    Spinner,
    'no-ssr': NoSSR
  },
  validations () {
    const productValidation = {
      categories: {
        customArrayRequired
      },
      uploadedImages: {
        customObjRequired
      }
    }
    for (const attrCode of this.getSortedProductAttributesNames) {
      if (attrCode === 'phone_number') {
        productValidation[attrCode] = {}
        continue
      }
      const inputType = this.preparedProductsByLang[attrCode].inputType
      const validationRule = this.getValidationRuleForType(inputType)
      productValidation[attrCode] = validationRule
    }
    return {
      product: productValidation
    }
  },
  data () {
    return {
      product: null
    }
  },
  computed: {
    ...mapGetters({
      getProducts: 'selling/getProducts',
      getEmptyProduct: 'selling/getEmptyProduct',
      getAllCategories: 'selling/getAllCategories',
      getProductsCount: 'selling/getProductsCount',
      getFullAttributesMap: 'selling/getFullAttributesMap',
      getDropdownAttrsList: 'selling/getDropdownAttrsList',
      getDropdownAttrsOptions: 'selling/getDropdownAttrsOptions',
      getPreparedCategoriesOptions: 'selling/getPreparedCategoriesOptions',
      getPreparedProductAttribures: 'selling/getPreparedProductAttribures',
      getPreparedDropdownAttrsOptions: 'selling/getPreparedDropdownAttrsOptions',
      getSortedProductAttributesNames: 'selling/getSortedProductAttributesNames'
    }),
    storeView () {
      return currentStoreView()
    },
    profitCount () {
      return this.product.marketplace_price * config.akeneo.profitFactor
    },
    profitPercent () {
      return `(${config.akeneo.profitFactor * 100}%)`
    },
    mustBeValidated () {
      return !this.getProductsCount || (this.getProductsCount && this.$v.$anyDirty)
    },
    langCode () {
      return this.storeView.i18n.defaultLocale.replace('-', '_')
    },
    preparedProductsByLang () {
      return this.getPreparedProductAttribures(this.langCode)
    },
    preparedDropdownAttrsOptionsByLang () {
      return this.getPreparedDropdownAttrsOptions(this.langCode)
    },
    preparedCategoriesOptionsByLang () {
      return this.getPreparedCategoriesOptions(this.langCode)
    }
  },
  async beforeMount () {
    this.$store.dispatch('selling/getAllCategories')
    this.emptyProduct()
  },
  methods: {
    async changeCategory () {
      const getFirstCategory = this.product?.categories[0]
      const categoryId = getFirstCategory?.value
      if (!categoryId) return
      this.emptyProductVariants()
      await this.$store.dispatch('selling/getFamilyForCategory', categoryId)
      await this.$store.dispatch('selling/getAttributes')
      await this.$store.dispatch('selling/getDropdownAttrsOptions', this.getDropdownAttrsList)
    },
    getValidationRuleForType (inputType) {
      const currValidationRule = {}
      if (inputType === 'multiSelect') {
        currValidationRule['customArrayRequired'] = customArrayRequired
      } else {
        currValidationRule['required'] = required
      }
      if (inputType === 'number') {
        currValidationRule['positive'] = positive
      }
      return currValidationRule
    },
    emptyProduct () {
      const additionalProductAttrsList = cloneDeep(this.$store.state.selling.additionalProductAttrsList)
      this.product = { ...additionalProductAttrsList }
    },
    emptyProductVariants () {
      const additionalProductAttrsList = cloneDeep(this.$store.state.selling.additionalProductAttrsList)
      this.product = { ...additionalProductAttrsList, categories: this.product.categories }
    },
    onFileImport (event) {
      if (!event.target.files.length) return
      let numberInput = event.target.id.split('-')[1]
      if (!numberInput) return
      this.product.uploadedImages[numberInput] = event.target.files[0]
      let oFReader = new FileReader()
      oFReader.readAsDataURL(event.target.files[0])
      this.$v.product.uploadedImages.$touch()
      // todo add image validation
      oFReader.onload = function (oFREvent) {
        let img = document.createElement('img')
        img.setAttribute('id', `${numberInput}Image`)
        img.src = oFREvent.target.result
        let imageBox = document.getElementById(`previewImageBox${numberInput}`)
        if (!imageBox) return
        imageBox.innerHTML = ''
        imageBox.appendChild(img)
      }
    },
    goNextStep () {
      // add product
      this.$v.$touch()
      this.addProduct(this.product)
      // set next step
      this.$store.dispatch('selling/afterProductSection')
      if (isCanBeReached('shipping')) this.$store.dispatch('selling/goToStep', 'shipping')
    },
    addProduct (product) {
      if (this.mustBeValidated && !this.$v.$invalid) {
        product.identifier = prepareIdentifier(product)
        product.product_form_lang = currentStoreView().storeCode
        this.$store.commit('selling/addProduct', product)
        this.clearProductForm()
      }
    },
    clearProductForm () {
      this.emptyProduct()
      document.getElementById(`imageInput-image`).value = null
      document.getElementById(`imageInput-back_image`).value = null
      document.getElementById(`imageInput-side_image`).value = null
      document.getElementById(`imageInput-label_image`).value = null
      this.$v.$reset()
    }
  }
}
</script>

<style lang="scss" scoped>
  .step-title {
    margin-top: 40px;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 1.94px;
    text-transform: uppercase;
  }
  .imageInput {
    input[type="file"] {
      display: none;
    }
    &-label {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f2f2f2;
      font-size: 12px;
      cursor: pointer;
      text-align: center;
    }
    &-outer {
      border: 2px dashed #D6D8DE;
      border-radius: 15px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
    }
    &-title {
      margin: 5px 0;
      font-weight: 600;
      text-align: center;
    }
    &-inner {
      min-height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-bottom: 10px;
    }
    &-thirdText {
      font-size: 12px;
    }
    .image-name {
      position: absolute;
      z-index: 1;
      margin: 0;
    }
    .svg-plus {
      position: absolute;
      right: 12px;
      top: 5px;
    }
    .svg-camera {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    .icon-close {
      position: absolute;
      font-size: 18px;
      font-weight: 600;
      right: 8px;
      top: 0;
    }
  }
  /deep/ .preview-image-box {
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20%;
    right: 20%;
    & > img {
      width: auto;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
  .profit-paragraph {
    margin: 11px 0;
    font-size: 13px;
    text-align: left;
    padding-left: 10px;
  }
  .justify-center {
    justify-content: center;
  }
  .product-description {
    margin-top: -5px;
  }
  /deep/ .bold-input {
    & input, select, textarea {
      font-weight: 600
    }
    & textarea {
      padding-left: 10px;
    }
  }
</style>
