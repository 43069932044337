<template>
  <div class="tooltip">
    <div v-if="!disabled" class="text">
      {{ text }}
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-gray: color(gray);

.tooltip {
  display: inline-block;
  cursor: default;
  position: relative;
  &:hover .text {
    display: block;
  }
}
.text {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -115%);
  width: 40vw;
  max-width: 250px;
  background-color: $color-gray;
  color: white;
  border-radius: 5px;
  display: none;
  padding: 10px 15px;
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
  margin-right: 20px;
  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border: 10px solid transparent;
    border-top: 10px solid $color-gray;
    left: 50%;
    transform: translateX(-50%);
    bottom: -20px;
  }
}
</style>
